import { devEnabled } from 'core/utils/helpers'
import account from './account'
// import argo from './argo'
import developer from './developer'
import kubernetes from './kubernetes'
import infrastructure from './infrastructure'
import kubevirt from './kubevirt'
// import metal3 from './metal3'
import ListAction from 'core/actions/ListAction'
import listActionsByKey from 'app/listActionsByKey'

/** @warning if you add a plugin, make sure to add its corresponding feature flag */
const allPlugins = [
  // Order here is important as it will define the default Dashboard route
  // for fallback routes (when trying to reach the base url)
  infrastructure,
  kubernetes,
  kubevirt,
  // metal3,
  // argo,
  account,
  ...(devEnabled() ? [developer] : []),
]

// Load the ListActions here since they depend on ApiClient being initialized (on bootstrap.ts)
ListAction.initDependencies(listActionsByKey)

export default allPlugins
