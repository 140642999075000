import ApiService from 'api-client/ApiService'
import { createUrlWithQueryString } from 'core/plugins/route'
import config from 'app-config'
import { Repository, RepositoryMetadata } from 'k8s/components/app-catalog/repositories/models'
import { DeployedApp, DeployedAppDetails } from 'k8s/components/app-catalog/deployed-apps/model'
import { App, AppDetails } from 'k8s/components/app-catalog/apps/models'
class Helm extends ApiService {
  public getClassName() {
    return 'helm'
  }

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get baseUrl(): string {
    return '/pf9helm'
  }

  getRepository = async (repoName) => {
    const url = `${this.baseUrl}/repos/${repoName}`
    return this.client.basicGet<Repository>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRepository',
      },
    })
  }

  getRepositories = async () => {
    const url = `${this.baseUrl}/repos`
    return this.client.basicGet<Repository[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRepositories',
      },
    })
  }

  createRepository = async (body) => {
    const url = `${this.baseUrl}/repos`
    const data = await this.client.basicPost<RepositoryMetadata>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createRepository',
      },
    })
    const repositories = await this.getRepositories()
    return repositories.find(
      (repository) => repository.repo?.name === data?.name && repository.repo?.url === data?.url,
    )
  }

  updateRepository = async (body) => {
    const url = `${this.baseUrl}/repos`
    const data = await this.client.basicPatch<RepositoryMetadata>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateRepository',
      },
    })
    const repositories = await this.getRepositories()
    return repositories.find(
      (repository) => repository.repo?.name === data?.name && repository.repo?.url === data?.url,
    )
  }

  deleteRepository = async (repoName) => {
    const url = `${this.baseUrl}/repos`
    const data = { name: repoName }
    return this.client.basicDelete<string>({
      url,
      data,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRepository',
      },
    })
  }

  syncRepositories = async (body) => {
    const url = `${this.baseUrl}/sync-repos`
    return this.client.basicPost<string>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAllRepositories',
      },
    })
  }

  addClustersToRepository = async (repoName, body) => {
    const url = `${this.baseUrl}/repos/${repoName}/clusters`
    return this.client.basicPost<string>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addClustersToRepository',
      },
    })
  }

  deleteClustersFromRepository = async (repoName, body) => {
    const url = `${this.baseUrl}/repos/${repoName}/clusters`
    const data = body
    return this.client.basicDelete<string>({
      url,
      data,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClustersFromRepository',
      },
    })
  }

  getRepositoriesForCluster = async (clusterId) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/repos`
    return this.client.basicGet<RepositoryMetadata[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRepositoriesForCluster',
      },
    })
  }

  getCharts = async () => {
    const url = `${this.baseUrl}/charts`
    return this.client.basicGet<App[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCharts',
      },
    })
  }

  getChartsForCluster = async (clusterId) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/repos/charts`
    return this.client.basicGet<App[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getChartsForCluster',
      },
    })
  }

  getChartInfo = async (repoName, name, params) => {
    const url = createUrlWithQueryString(`${this.baseUrl}/charts/${repoName}/${name}/info`, params)
    return this.client.basicGet<AppDetails>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getChartInfo',
      },
    })
  }

  deployChart = async (clusterId, namespace, body) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/namespaces/${namespace}/releases`
    await this.client.basicPost<string>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deployChart',
      },
    })
    const releases = await this.getReleases(clusterId)
    return releases?.find(
      (release) => release.namespace === namespace && release.name === body.Name,
    )
  }

  getReleases = async (clusterId) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/releases`
    return this.client.basicGet<DeployedApp[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getReleases',
      },
    })
  }

  deleteRelease = async (clusterId, namespace, data) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/namespaces/${namespace}/releases`
    return this.client.basicDelete<string>({
      url,
      data,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRelease',
      },
    })
  }

  updateRelease = async (clusterId, namespace, chart, body) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/namespaces/${namespace}/releases`
    await this.client.basicPut<string>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateRelease',
      },
    })
    const releases = await this.getReleases(clusterId)
    return releases?.find(
      (release) =>
        release.namespace === namespace && release.chart === chart && release.name === body.Name,
    )
  }

  getReleaseInfo = async (clusterId, namespace, releaseName) => {
    const url = `${this.baseUrl}/clusters/${clusterId}/namespaces/${namespace}/releases/${releaseName}`
    const data = await this.client.basicGet<DeployedAppDetails[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getReleaseInfo',
      },
    })
    return [{ ...data, clusterId }]
  }
}

export default Helm
