import BugsnagCLI from '@bugsnag/js'
import {
  Client,
  NotifiableError,
  OnErrorCallback,
  Config,
  BreadcrumbType,
  Event,
} from '@bugsnag/core/types'

let _instance = null
class Bugsnag {
  initialized = false

  public start(options: string | Config): Client {
    if (this.initialized) {
      return _instance
    }
    this.initialized = true
    _instance = BugsnagCLI.start(options)
    return _instance
  }

  public getPlugin(name: string) {
    if (!this.initialized) {
      return
    }
    return BugsnagCLI.getPlugin(name)
  }

  public setUser(userId?: string, email?: string, name?: string) {
    if (!this.initialized) {
      return
    }
    return BugsnagCLI.setUser(userId, email, name)
  }

  public addMetadata(section: string, values: Record<string, any>) {
    if (!this.initialized) {
      return
    }
    return BugsnagCLI.addMetadata(section, values)
  }

  public notify(
    error: NotifiableError,
    onError?: OnErrorCallback,
    cb?: (err: any, event: Event) => void,
  ) {
    if (!this.initialized) {
      return
    }
    return BugsnagCLI.notify(error, onError, cb)
  }

  public leaveBreadcrumb(
    message: string,
    metadata?: Record<string, any>,
    crumbType?: BreadcrumbType,
  ) {
    if (!this.initialized) {
      return
    }
    return BugsnagCLI.leaveBreadcrumb(message, metadata, crumbType)
  }
}

export default new Bugsnag()

interface IWindow extends Window {
  customLogger: (msg: string) => void
  process: any
}
declare let window: IWindow

export const developmentLogger = {
  debug: function() {
    if (window.customLogger) {
      window.customLogger.apply(this, arguments)
    }
  },
  info: function() {
    if (window.customLogger) {
      window.customLogger.apply(this, arguments)
    }
  },
  warn: function() {
    if (window.customLogger) {
      window.customLogger.apply(this, arguments)
    }
  },
  error: function() {
    if (window.customLogger) {
      window.customLogger.apply(this, arguments)
    }
  },
}
