import React from 'react'
import { createRoot } from 'react-dom/client'
import './bootstrap'
import './app.css'

import App from './app'

window.process = process
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)
