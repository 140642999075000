import { hot } from 'react-hot-loader'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import HotKeysProvider from 'core/providers/HotKeysProvider'
import ToastProvider from 'core/providers/ToastProvider'
import BannerProvider from 'core/providers/BannerProvider'
import AppContainer from 'core/containers/AppContainer'
import { BrowserRouter as Router } from 'react-router-dom'
import allPlugins from 'app/plugins'
import pluginManager from 'core/plugins/pluginManager'
import store, { persistor } from './store'
import Progress from 'core/components/progress/Progress'
import ThemeManager from 'core/themes/ThemeManager'

import { getErrorBoundary } from './core/components/ErrorBoundary'
import { initErrorHandling } from './bootstrap/error-handling'
import { getFeatures } from 'core/containers/AppController'

allPlugins.forEach((plugin) => pluginManager.registerPlugin(plugin))

function maybeDeregisterPlugin({ id, isDisabled }) {
  if (isDisabled) {
    pluginManager.deregisterPlugin(id)
  }
}

const App = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function load() {
      try {
        const { airgapped, plugins } = await getFeatures()
        plugins.forEach(maybeDeregisterPlugin)
        if (!airgapped) {
          initErrorHandling()
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    load()
  }, [])
  const progressInstance = (
    <Progress renderLoadingImage={false} loading message={'Loading app...'} />
  )
  const ErrorBoundary = getErrorBoundary()
  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={progressInstance} persistor={persistor}>
          {loading ? (
            progressInstance
          ) : (
            <ErrorBoundary>
              <ThemeManager>
                <ToastProvider>
                  <BannerProvider>
                    <AppContainer />
                  </BannerProvider>
                </ToastProvider>
              </ThemeManager>
            </ErrorBoundary>
          )}
        </PersistGate>
      </Provider>
    </Router>
  )
}

export default hot(module)(App)
