import { withStyles } from '@material-ui/styles'
import { compose } from 'app/utils/fp'
import Text from 'core/elements/Text'
import StoreViewer from 'developer/components/redux-store-display'
import PropTypes from 'prop-types'
import React from 'react'
import packageJson from '../../../../../package.json'

const { version } = packageJson

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  panel: {
    width: '100%',
  },
  details: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
})

class DeveloperToolsEmbed extends React.PureComponent {
  render() {
    const {
      props: { enabled, classes },
    } = this

    if (!enabled) {
      return null
    }
    return (
      <div className={classes.root}>
        <Text className={classes.title} variant="subtitle1">
          Developer Tools. V{version}
        </Text>
        <StoreViewer />
      </div>
    )
  }
}

DeveloperToolsEmbed.propTypes = {
  enabled: PropTypes.bool,
}

DeveloperToolsEmbed.defaultProps = {
  enabled: true,
}

export default compose(withStyles(styles))(DeveloperToolsEmbed)
