import React from 'react'
import SearchBar from 'core/components/SearchBar'
import JsonView from 'react-json-view'
import { connect } from 'react-redux'
import { RootState } from 'app/store'
import { pathStrOr } from 'utils/fp'
import { memoize } from 'utils/misc'

interface Props {
  store: RootState
}

// @ts-ignore
@connect((store) => ({ store }))
class StoreViewer extends React.PureComponent<Props> {
  state = { searchTerm: '' }
  handleSearchChange = (searchTerm) => this.setState({ searchTerm })
  moizedSearch = memoize((searchTermPath) => {
    const { store } = this.props
    return pathStrOr(store, searchTermPath, store)
  })
  render() {
    const { store } = this.props
    const { searchTerm } = this.state
    const searchedContext = this.moizedSearch(searchTerm)
    return (
      <div>
        <SearchBar onSearchChange={this.handleSearchChange} searchTerm={searchTerm} />
        <hr />
        <JsonView src={searchedContext} collapsed={1} enableClipboard />
      </div>
    )
  }
}

export default StoreViewer
