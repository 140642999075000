import Bugsnag, { developmentLogger } from 'utils/bugsnag'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import packageJson from '../../../package.json'

const { version } = packageJson

export function initErrorHandling() {
  Bugsnag.start({
    releaseStage: window.process.env.NODE_ENV,
    apiKey: window.process.env.BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: version,
    enabledReleaseStages: ['production'],
    redactedKeys: [
      'Authorization', // remove tokens from headers (Authorization: "Bearer <token>")
      /^X-Auth-Token$/i, // remove cookie token
      /^xsrf/,
      /^password$/i, // remove any times we try to report a password
    ],
    logger: window.process.env.NODE_ENV !== 'production' ? developmentLogger : null,
  })
}
