import ApiService from 'api-client/ApiService'
import { PreferenceStoreResponse } from './preference-store.model'
import { GlobalPreferences, UserPreferences } from 'app/constants'
import Bugsnag from 'utils/bugsnag'
import config from 'app-config'

class PreferenceStore extends ApiService {
  public getClassName() {
    return 'preferenceStore'
  }

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get baseUrl(): string {
    return '/preference-store'
  }

  getUserPreference = async (userId, key: UserPreferences) => {
    Bugsnag.leaveBreadcrumb('Attempting to get user preference', { userId, key })
    return this.client.basicGet({
      url: `${this.baseUrl}/user/${userId}/${key}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getUserPreference',
      },
    })
  }

  setUserPreference = async (userId, key: UserPreferences, value) => {
    Bugsnag.leaveBreadcrumb('Attempting to update user preference', { userId, key, value })
    const jsonString = JSON.stringify(value)
    const body = {
      value: jsonString,
    }
    return this.client.basicPut({
      url: `${this.baseUrl}/user/${userId}/${key}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addUserPreference',
      },
    })
  }

  deleteUserPreference = async (userId, key: UserPreferences) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete user preference', { userId, key })
    return this.client.basicDelete({
      url: `${this.baseUrl}/user/${userId}/${key}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteUserPreference',
      },
    })
  }

  getGlobalPreference = async (key: GlobalPreferences) => {
    Bugsnag.leaveBreadcrumb('Attempting to get global preference', { key })
    return this.client.basicGet<PreferenceStoreResponse>({
      url: `${this.baseUrl}/global/${key}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getGlobalPreference',
      },
    })
  }

  updateGlobalPreference = async (key: GlobalPreferences, value) => {
    Bugsnag.leaveBreadcrumb('Attempting to update global preference', { key, value })
    const jsonString = JSON.stringify(value)
    const body = {
      value: jsonString,
    }
    return this.client.basicPut({
      url: `${this.baseUrl}/global/${key}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateGlobalPreference',
      },
    })
  }

  deleteGlobalPreference = async (key: GlobalPreferences) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete global preference', { key })
    return this.client.basicDelete({
      url: `${this.baseUrl}/global/${key}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteGlobalPreference',
      },
    })
  }
}

export default PreferenceStore
