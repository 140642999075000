import uuid from 'uuid'

export const isMetal3Cluster = (cluster) => !!cluster.enableMetal3

export const metal3InfoText = `Metal³ makes it possible for Bare Metal hosts to be managed by 
    Kubernetes.`
export const metal3EnableText = `Enable Metal³ and start deploying and managing bare metal hosts in 
    your Kubernetes clusters now.`

export const parseMetal3IpAddressRange = (addressRange) => {
  if (!addressRange) return []
  const [start, end] = addressRange.split(',')
  return [{ key: start, value: end, id: uuid.v4() }]
}

export const getBareMetalHostCounts = (bareMetalHostsList = []) => {
  const counts = { numHosts: 0, numProvisionedHosts: 0, numHostsReady: 0 }
  bareMetalHostsList.forEach((host) => {
    counts.numHosts += 1
    if (host.isProvisioned) {
      counts.numProvisionedHosts += 1
    } else {
      counts.numHostsReady += 1
    }
  })
  return counts
}
