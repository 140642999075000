import jsYaml from 'js-yaml'
import { filter, identical, map, pipe, tap } from 'ramda'
import { isNilOrEmpty, pathStr } from 'utils/fp'

export enum PathPrefixTypes {
  Path = 'path:', // find the value at the given path from the values
  PathNotNull = 'pathNotNull:', // remove the entire entry from yaml if the path is not found in the values
  CustomPathString = 'customPathString:', // behaves like template literal
}

const interpolate = (str, params) => {
  const names = Object.keys(params)
  const vals = Object.values(params)
  return new Function(...names, `return \`${str}\`;`)(...vals)
}

const test = (customString, values) => {
  return customString.replace(/\${(.*?)\}/g, (match, path) => pathStr(path, values) || '')
}

export default class YamlTemplateParser {
  private values: Record<string, unknown> = {}

  constructor(private schema: Record<string, unknown>) {}

  setValues(values: Record<string, unknown>) {
    this.values = values
  }

  private parse(obj) {
    return pipe(
      // tap((value) => console.log(value)), // uncomment to debug
      map((value) =>
        typeof value === 'object'
          ? isNilOrEmpty(value)
            ? value
            : this.parse(value)
          : typeof value === 'string' && value.startsWith(PathPrefixTypes.Path)
          ? this.findPath(value)
          : value,
      ),
      map((value) =>
        typeof value === 'object'
          ? isNilOrEmpty(value)
            ? value
            : this.parse(value)
          : typeof value === 'string' &&
            value.startsWith(PathPrefixTypes.PathNotNull) &&
            this.findPath(value)
          ? this.findPath(value)
          : value,
      ),
      map((value) =>
        typeof value === 'object'
          ? isNilOrEmpty(value)
            ? value
            : this.parse(value)
          : typeof value === 'string' &&
            value.startsWith(PathPrefixTypes.CustomPathString) &&
            this.getCustomPathString(value)
          ? this.getCustomPathString(value)
          : value,
      ),
      filter((value) =>
        typeof value === 'string' && value.startsWith(PathPrefixTypes.PathNotNull) ? false : true,
      ),
    )(obj)
  }

  private findPath(str) {
    const path = str.startsWith(PathPrefixTypes.PathNotNull) ? str.substr(12) : str.substr(5)
    const value = pathStr(path, this.values)
    return value
  }

  getJson() {
    try {
      return this.parse(this.schema)
    } catch (error) {
      console.error('Error parsing schema ', this.schema, error)
    }
  }

  // Todo: add some error-proofing
  private getCustomPathString(str) {
    const customString = str.substr(17)
    return customString.replace(/\${(.*?)\}/g, (match, path) => pathStr(path, this.values) || '')
  }

  toString() {
    try {
      const parsedJson = this.parse(this.schema)
      return jsYaml.dump(parsedJson)
    } catch (error) {
      console.error('Error parsing schema ', this.schema, error)
    }
  }
}
