import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { someAsync } from 'utils/async'
import {
  addonTypeToNameMap,
  metal3Params,
  metalLbParams,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/helpers'
import { pick, flatten } from 'ramda'
import { ClusterAddonType } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

export const enableMetal3 = async (clusterId, values, enableAddonFn, checkForMetalLb = true) => {
  Bugsnag.leaveBreadcrumb('Attempting to get addon versions')
  const currentAddonVersions = (await qbert.getAddonVersions(clusterId)) || {}
  const metal3Version = currentAddonVersions[addonTypeToNameMap[ClusterAddonType.Metal3]]

  let enablingMetalLbSuccess = false
  // Enable Metal3
  const { success: enablingMetal3Success } = await enableAddonFn({
    clusterId,
    type: ClusterAddonType.Metal3,
    version: metal3Version,
    overrideParams: pick(metal3Params, values),
  })

  if (checkForMetalLb) {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster addons')
    const existingClusterAddons = await qbert.getClusterAddons(clusterId)
    const metalLbEnabled = !!existingClusterAddons.find(
      (addon) => addon.spec?.type === ClusterAddonType.MetalLb,
    )

    if (!metalLbEnabled) {
      // Enable MetalLb
      const { success } = await enableAddonFn({
        clusterId,
        type: ClusterAddonType.MetalLb,
        version: currentAddonVersions[addonTypeToNameMap[ClusterAddonType.MetalLb]],
        overrideParams: pick(metalLbParams, values),
      })

      enablingMetalLbSuccess = success
    }
  }
  return enablingMetalLbSuccess || enablingMetal3Success
}

const bareMetalHostActions = ActionsSet.make<DataKeys.BareMetalHosts>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.BareMetalHosts,
  cacheKey: DataKeys.BareMetalHosts,
})

export const listBareMetalHosts = bareMetalHostActions.add(
  new ListAction<DataKeys.BareMetalHosts, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Bare Metal Hosts')
    const clusterIds = parseClusterIdsFromParams(params)
    return someAsync(clusterIds.map(qbert.getBareMetalHosts)).then(flatten)
  }),
)
