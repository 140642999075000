import ApiService from 'api-client/ApiService'
import {
  generateSunpikeErrorPayload,
  trackApiMethodMetadata,
  constructApiUrl,
} from 'api-client/helpers'
import config from 'app-config'
import {
  CapiCluster,
  GetCapiClustersResponse,
  AwsAmis,
} from 'app/plugins/infrastructure/components/clusters/capi/model'
import {
  GetAwsClustersReponse,
  CapiAwsCluster,
} from 'app/plugins/infrastructure/components/clusters/aws/capi/model'
import {
  GetNodeletControlPlanesResponse,
  NodeletControlPlane,
} from 'app/plugins/infrastructure/components/clusters/capi/control-plane/nodelet-control-plane/model'
import {
  AwsManagedControlPlane,
  GetAwsManagedControlPlanesResponse,
} from 'app/plugins/infrastructure/components/clusters/capi/control-plane/aws-managed-control-plane/model'
import {
  MachineDeploymentList,
  MachineDeployment,
} from 'app/plugins/infrastructure/components/clusters/capi/machine-deployment/model'
import { createUrlWithQueryString } from 'core/plugins/route'
import {
  MachinePool,
  MachinePoolList,
} from 'app/plugins/infrastructure/components/clusters/capi/machine-pool/model'
import {
  AwsManagedMachinePool,
  AwsManagedMachinePoolList,
} from 'app/plugins/infrastructure/components/clusters/capi/aws-managed-machine-pool/model'
import {
  AwsMachineTemplate,
  AwsMachineTemplateList,
} from 'app/plugins/infrastructure/components/clusters/capi/aws-machine-templates/model'
import {
  EksConfigList,
  EksConfigTemplate,
  EksConfigTemplateList,
  NodeletConfig,
  NodeletConfigList,
  EksConfig,
  NodeletConfigTemplate,
} from 'app/plugins/infrastructure/components/clusters/capi/configs/model'
import {
  AwsMachinePool,
  AwsMachinePoolList,
} from 'app/plugins/infrastructure/components/clusters/capi/aws-machine-pool/model'
import { cleanResource } from 'app/plugins/infrastructure/components/clusters/aws/capi/capiUtils'
import {
  GetAwsClusterStaticIdentitiesResponse,
  AwsClusterStaticIdentity,
} from 'app/plugins/infrastructure/components/clusters/capi/identities/models'
import { dissocPath } from 'ramda'
import { EventsResponse } from 'app/plugins/infrastructure/components/clusters/model'
import {
  ClusterAddon,
  ClusterAddons,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import { GetCapiHostsResponse } from 'app/plugins/infrastructure/components/clusters/capi/details/overview/hosts/model'
import { GetEksClusterVersionsResponse } from 'app/plugins/infrastructure/components/versions/eks/model'
import { AwsRegionInfo } from 'app/plugins/infrastructure/components/cloudProviders/model'
import {
  ClusterUpgradeJob,
  GetClusterUpgradeJobsResponse,
} from 'app/plugins/infrastructure/components/clusters/capi/upgrade/model'
import { GetEksAmiTypesResponse } from 'app/plugins/infrastructure/components/clusters/capi/eks-ami-types/model'
import { sunpikeBaseUrlTpl } from './constants'
import { PrometheusResponse } from 'app/plugins/infrastructure/components/clusters/capi/details/overview/model'
class Sunpike extends ApiService {
  public getClassName() {
    return 'sunpike'
  }

  static apiMethodsMetadata = []

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get baseUrl() {
    return '/sunpike'
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/events.k8s.io/v1/namespaces/:namespace/events',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'CapiEvents' },
  })
  getCapiClusterEvents = async (clusterName: string, namespace = 'default') => {
    const url = `${this.baseUrl}/apis/opt.pf9.io/v1beta1/namespaces/${namespace}/clusters/${clusterName}/proxy/api/v1/events`
    const data = await this.client.basicGet<EventsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCapiClusterEvents',
      },
    })

    return data?.items || []
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/clusters',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'CapiClusters' },
  })
  getCapiClusters = async () => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/clusters`
    const data = await this.client.basicGet<GetCapiClustersResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCapiClusters',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters/:name',
    type: 'GET',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiCluster' },
  })
  getCapiCluster = async (namespace = 'default', name) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/clusters/${name}`
    const data = await this.client.basicGet<CapiCluster>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCapiCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'CapiCluster' },
  })
  createCapiCluster = async (body: CapiCluster, namespace = 'default') => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/clusters`
    const data = await this.client.basicPost<CapiCluster>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCapiCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiCluster' },
  })
  updateCapiCluster = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/clusters/${name}`
    const data = await this.client.basicPut<CapiCluster>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCapiCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  deleteCapiCluster = async (namespace, name) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/clusters/${name}`
    const data = await this.client.basicDelete<CapiCluster>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCapiCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusters',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  getCapiAwsClusters = async () => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusters`
    const data = await this.client.basicGet<GetAwsClustersReponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCapiAwsClusters',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsclusters/:name',
    type: 'GET',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  getCapiAwsCluster = async (namespace = 'default', name) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsclusters/${name}`
    const data = await this.client.basicGet<CapiAwsCluster>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCapiAwsCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsclusters',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  createCapiAwsCluster = async (body, namespace = 'default') => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsclusters`
    const data = await this.client.basicPost<CapiAwsCluster>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCapiAwsCluster',
      },
    })
    return cleanResource()(data)
  }
  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsclusters/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  updateCapiAwsCluster = async (namespace = 'default', name, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsclusters/${name}`
    const data = await this.client.basicPut<CapiAwsCluster>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCapiAwsCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsclusters/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  deleteCapiAwsCluster = async (namespace = 'default', name) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsclusters/${name}`
    const data = await this.client.basicDelete<CapiAwsCluster>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCapiAwsCluster',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsclusters/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CapiAwsClusters' },
  })
  partiallyUpdateCapiAwsCluster = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsclusters/${name}`
    const data = await this.client.basicPatch<CapiAwsCluster>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'partiallyUpdateCapiAwsCluster',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclustercontrolleridentities/:name',
    type: 'PUT',
    params: ['name'],
    error: { isClusterError: true, k8sResource: 'AWSClusterControllerIdentity' },
  })
  updateAwsClusterControllerIdentity = async (name, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclustercontrolleridentities/${name}`
    const data = await this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAwsClusterControllerIdentity',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterstaticidentities',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'AWSClusterStaticIdentities' },
  })
  getAwsClusterStaticIdentities = async () => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterstaticidentities`
    const data = await this.client.basicGet<GetAwsClusterStaticIdentitiesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsClusterStaticIdentities',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterstaticidentities/:name',
    type: 'PUT',
    params: ['name'],
    error: { isClusterError: true, k8sResource: 'AWSClusterStaticIdentities' },
  })
  updateAwsClusterStaticIdentity = async (name, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterstaticidentities/${name}`
    const data = await this.client.basicPut<AwsClusterStaticIdentity>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAwsClusterStaticIdentity',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'AWSClusterRoleIdentity' },
  })
  getAwsClusterRoleIdentities = async () => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsClusterRoleIdentities',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities',
    type: 'POST',
    params: ['body'],
    error: { isClusterError: true, k8sResource: 'AWSClusterRoleIdentity' },
  })
  createAwsClusterRoleIdentity = async (body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities`
    const data = await this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createAwsClusterRoleIdentity',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities/:name',
    type: 'PUT',
    params: ['name'],
    error: { isClusterError: true, k8sResource: 'AWSClusterRoleIdentity' },
  })
  updateAwsClusterRoleIdentity = async (name, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities/${name}`
    const data = await this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAwsClusterRoleIdentity',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities/:name',
    type: 'DELETE',
    params: ['name'],
    error: { isClusterError: true, k8sResource: 'AWSClusterRoleIdentity' },
  })
  deleteAwsClusterRoleIdentity = async (name) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsclusterroleidentities/${name}`
    const data = await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteAwsClusterRoleIdentity',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/nodeletcontrolplanes',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'NodeletControlPlanes' },
  })
  getNodeletControlPlanes = async () => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/nodeletcontrolplanes`
    const data = await this.client.basicGet<GetNodeletControlPlanesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodeletControlPlanes',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletcontrolplanes',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'NodeletControlPlanes' },
  })
  createNodeletControlPlane = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/nodeletcontrolplanes`
    const data = await this.client.basicPost<NodeletControlPlane>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createNodeletControlPlane',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletcontrolplanes/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletControlPlanes' },
  })
  updateNodeletControlPlane = async (namespace = 'default', name, body) => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletcontrolplanes/${name}`
    const data = await this.client.basicPut<NodeletControlPlane>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateNodeletControlPlane',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletcontrolplanes/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletControlPlanes' },
  })
  patchNodeletControlPlane = async (namespace = 'default', name, body) => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletcontrolplanes/${name}`
    const data = await this.client.basicPatch<NodeletControlPlane>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchNodeletControlPlane',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletcontrolplanes/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletControlPlanes' },
  })
  deleteNodeletControlPlane = async (namespace = 'default', name) => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletcontrolplanes/${name}`
    const data = await this.client.basicDelete<NodeletControlPlane>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteNodeletControlPlane',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/awsmanagedcontrolplanes',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'AWSManagedControlPlanes' },
  })
  getAwsManagedControlPlanes = async () => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/awsmanagedcontrolplanes`
    const data = await this.client.basicGet<GetAwsManagedControlPlanesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsManagedControlPlanes',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachineDeployments' },
  })
  updateMachineDeployment = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/machinedeployments/${name}`
    const data = await this.client.basicPut<MachineDeployment>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateMachineDeployment',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachineDeployments' },
  })
  patchMachineDeployment = async (resource, customBody = undefined) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/machinedeployments/${name}`
    const data = await this.client.basicPatch<MachineDeployment>({
      url,
      body: customBody || resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchMachineDeployment',
        config: {
          headers: {
            'Content-Type': customBody
              ? 'application/json-patch+json'
              : 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmanagedcontrolplanes/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSManagedControlPlanes' },
  })
  patchAwsManagedControlPlane = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmanagedcontrolplanes/${name}`
    const data = await this.client.basicPatch<AwsManagedControlPlane>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchAwsManagedControlPlane',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmanagedcontrolplanes/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSManagedControlPlanes' },
  })
  updateAwsManagedControlPlane = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmanagedcontrolplanes/${name}`
    const data = await this.client.basicPut<AwsManagedControlPlane>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAwsManagedControlPlane',
      },
    })
    return cleanResource()(data)
  }
  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmanagedcontrolplanes',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'AWSManagedControlPlanes' },
  })
  createAwsManagedControlPlane = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/controlplane.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/awsmanagedcontrolplanes`
    const data = await this.client.basicPost<AwsManagedControlPlane>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createAwsManagedControlPlane',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/:identityName/amis?region=:region&kubernetes-version=:version',
    type: 'GET',
  })
  getAwsAmis = async (identityName: string, region: string, version?: string, roleArn?: string) => {
    const url = `${
      this.baseUrl
    }/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/${identityName}/amis?region=${region}${
      version ? `&kubernetes-version=${version}` : ''
    }${roleArn ? `&arn=${roleArn}` : ''}` // optinal query params
    const data = await this.client.basicGet<AwsAmis>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsAmis',
      },
    })
    return data.items
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/machinedeployments',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'MachineDeployments' },
  })
  getMachineDeployments = async (clusterName = '') => {
    const clusterLabel = `cluster.x-k8s.io/cluster-name=${clusterName}`
    const baseUrl = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/machinedeployments`
    const url = clusterName
      ? createUrlWithQueryString(baseUrl, { labelSelector: clusterLabel })
      : baseUrl
    const data = await this.client.basicGet<MachineDeploymentList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getMachineDeployments',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployments' },
  })
  createMachineDeployment = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/machinedeployments`
    const data = await this.client.basicPost<MachineDeployment>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createMachineDeployment',
      },
    })
    return cleanResource()(data)
  }
  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachineDeployments' },
  })
  deleteMachineDeployment = async (namespace, name) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/machinedeployments/${name}`
    const data = await this.client.basicDelete<MachineDeployment>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteMachineDeployment',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinetemplates/:name',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'AWSMachineTemplates' },
  })
  createAwsMachineTemplate = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/awsmachinetemplates`
    const data = await this.client.basicPost<AwsMachineTemplate>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createAwsMachineTemplate',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinetemplates/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSMachineTemplates' },
  })
  deleteAwsMachineTemplate = async (namespace, name) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmachinetemplates/${name}`
    const data = await this.client.basicDelete<AwsMachineTemplate>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteAwsMachineTemplate',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinetemplates/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSMachineTemplates' },
  })
  updateAwsMachineTemplate = async (resource) => {
    const { namespace, name } = resource?.metadata || {}
    try {
      await this.deleteAwsMachineTemplate(namespace, name)
    } catch (err) {
      console.error(err)
    }
    await this.createAwsMachineTemplate(resource)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1//awsmachinetemplates',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'MachineTemplates' },
  })
  getAwsMachineTemplates = async (clusterName = '') => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsmachinetemplates/${clusterName}`
    const data = await this.client.basicGet<AwsMachineTemplateList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsMachineTemplates',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinepools',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'MachinePools' },
  })
  createMachinePool = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/machinepools`
    const data = await this.client.basicPost<MachinePool>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/machinepools',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'MachinePools' },
  })
  getMachinePools = async (clusterName = '') => {
    const clusterLabel = `cluster.x-k8s.io/cluster-name=${clusterName}`
    const baseUrl = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/machinepools`
    const url = clusterName
      ? createUrlWithQueryString(baseUrl, { labelSelector: clusterLabel })
      : baseUrl
    const data = await this.client.basicGet<MachinePoolList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getMachinePools',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinepools/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachinePools' },
  })
  updateMachinePool = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/machinepools/${name}`
    const data = await this.client.basicPut<MachinePool>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinepools/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachinePools' },
  })
  patchMachinePool = async (resource) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/machinepools/${name}`
    const data = await this.client.basicPatch<MachinePool>({
      url,
      body: resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchMachinePool',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinepools/:name',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachinePools' },
  })
  deleteMachinePool = async (namespace, name) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${namespace}/machinepools/${name}`
    const data = await this.client.basicDelete<MachinePool>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsmachinepools',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'AWSMachinePools' },
  })
  getAwsMachinePools = async (clusterName = '') => {
    const clusterLabel = `cluster.x-k8s.io/cluster-name=${clusterName}`
    const baseUrl = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsmachinepools`
    const url = clusterName
      ? createUrlWithQueryString(baseUrl, { labelSelector: clusterLabel })
      : baseUrl
    const data = await this.client.basicGet<AwsMachinePoolList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsMachinePools',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinepools',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'AWSMachinePools' },
  })
  createAwsMachinePool = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/awsmachinepools`
    const data = await this.client.basicPost<AwsMachinePool>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createAwsMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinepools/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSMachinePools' },
  })
  updateAwsMachinePool = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmachinepools/${name}`
    const data = await this.client.basicPut<AwsMachinePool>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAwsMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinepools/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSMachinePools' },
  })
  patchAwsMachinePool = async (resource) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmachinepools/${name}`
    const data = await this.client.basicPatch<AwsMachinePool>({
      url,
      body: resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchAwsMachinePool',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsmanagedmachinepools',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'AWSManagedMachinePools' },
  })
  getAwsManagedMachinePools = async (clusterName = '') => {
    const clusterLabel = `cluster.x-k8s.io/cluster-name=${clusterName}`
    const baseUrl = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/awsmanagedmachinepools`
    const url = clusterName
      ? createUrlWithQueryString(baseUrl, { labelSelector: clusterLabel })
      : baseUrl
    const data = await this.client.basicGet<AwsManagedMachinePoolList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsManagedMachinePools',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmachinepools',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'AWSManagedMachinePools' },
  })
  createAwsManagedMachinePool = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/awsmanagedmachinepools`
    const data = await this.client.basicPost<AwsManagedMachinePool>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createAwsManagedMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmanagedmachinepools/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSManagedMachinePools' },
  })
  updateAwsManagedMachinePool = async (resource) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmanagedmachinepools/${name}`
    const data = await this.client.basicPut<AwsManagedMachinePool>({
      url,
      body: resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateAwsManagedMachinePool',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/awsmanagedmachinepools/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'AWSManagedMachinePools' },
  })
  patchAwsManagedMachinePool = async (resource, customBody = undefined) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/awsmanagedmachinepools/${name}`
    const data = await this.client.basicPatch<AwsManagedMachinePool>({
      url,
      body: customBody || dissocPath(['spec', 'eksNodegroupName'], resource),
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchAwsManagedMachinePool',
        config: {
          headers: {
            'Content-Type': customBody
              ? 'application/json-patch+json'
              : 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/nodeletconfigs',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'NodeletConfigs' },
  })
  getNodeletConfigs = async () => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/nodeletconfigs`
    const data = await this.client.basicGet<NodeletConfigList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodeletConfigs',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletconfigs',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'NodeletConfigs' },
  })
  createNodeletConfig = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/nodeletconfigs`
    const data = await this.client.basicPost<NodeletConfig>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createNodeletConfig',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletconfigs/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletConfigs' },
  })
  updateNodeletConfig = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletconfigs/${name}`
    const data = await this.client.basicPut<NodeletConfig>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateNodeletConfig',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletconfigs/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletConfigs' },
  })
  patchNodeletConfig = async (resource, customBody = undefined) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletconfigs/${name}`
    const data = await this.client.basicPatch<NodeletConfig>({
      url,
      body: customBody || resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchNodeletConfig',
        config: {
          headers: {
            'Content-Type': customBody
              ? 'application/json-patch+json'
              : 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/eksconfigs',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'EKSConfigs' },
  })
  getEksConfigs = async () => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/eksconfigs`
    const data = await this.client.basicGet<EksConfigList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEksConfigs',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigs',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'EKSConfigs' },
  })
  createEksConfig = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/eksconfigs`
    const data = await this.client.basicPost<EksConfig>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createEksConfig',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigs/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'EKSConfigs' },
  })
  updateEksConfig = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/eksconfigs/${name}`
    const data = await this.client.basicPut<EksConfig>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateEksConfig',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigs/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'EKSConfigs' },
  })
  patchEksConfig = async (resource) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/eksconfigs/${name}`
    const data = await this.client.basicPatch<EksConfig>({
      url,
      body: resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchEksConfig',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/eksconfigtemplates',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'EKSConfigTemplates' },
  })
  getEksConfigTemplates = async () => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/eksconfigtemplates`
    const data = await this.client.basicGet<EksConfigTemplateList>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEksConfigTemplates',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigtemplates',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'EKSConfigTemplates' },
  })
  createEksConfigTemplate = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/eksconfigtemplates`
    const data = await this.client.basicPost<EksConfigTemplate>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createEksConfigTemplate',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigtemplates/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'EKSConfigTemplates' },
  })
  updateEksConfigTemplate = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/eksconfigtemplates/${name}`
    const data = await this.client.basicPut<EksConfigTemplate>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateEksConfigTemplate',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigtemplates/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'EKSConfigTemplates' },
  })
  patchEksConfigTemplate = async (resource) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/eksconfigtemplates/${name}`
    const data = await this.client.basicPatch<EksConfigTemplate>({
      url,
      body: resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchEksConfigTemplate',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/nodeletconfigtemplates',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'NodeletConfigTemplates' },
  })
  getNodeletConfigTemplates = async () => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/nodeletconfigtemplates`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodeletConfigTemplates',
      },
    })
    return data.items.map(cleanResource())
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletconfigtemplates/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletConfigs' },
  })
  patchNodeletConfigTemplate = async (resource, customBody = undefined) => {
    const { namespace, name } = resource?.metadata || {}
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletconfigtemplates/${name}`
    const data = await this.client.basicPatch<NodeletConfigTemplate>({
      url,
      body: customBody || resource,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchNodeletConfigTemplates',
        config: {
          headers: {
            'Content-Type': customBody
              ? 'application/json-patch+json'
              : 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/nodeletconfigtemplates/:name',
    type: 'PUT',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NodeletConfigs' },
  })
  updateNodeletConfigTemplate = async (namespace, name, body) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${namespace}/nodeletconfigtemplates/${name}`
    const data = await this.client.basicPut<NodeletConfigTemplate>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateNodeletConfigTemplate',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/eksconfigtemplates',
    type: 'POST',
    params: ['body', 'namespace'],
    error: { isClusterError: true, k8sResource: 'EKSConfigTemplates' },
  })
  createNodeletConfigTemplate = async (body, namespace = undefined) => {
    const _namespace = namespace ? namespace : body?.metadata?.namespace || 'default'
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${_namespace}/nodeletconfigtemplates`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createNodeletConfigTemplate',
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/opt.pf9.io/v1beta1/namespaces/:namespace/clusters/:name/kubeconfig',
    type: 'POST',
    params: ['name', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Kubeconfig' },
  })
  downloadKubeconfig = async (name: string, body, namespace = 'default') => {
    const url = `${this.baseUrl}/apis/opt.pf9.io/v1beta1/namespaces/${namespace}/clusters/${name}/kubeconfig`
    try {
      const response = await this.client.basicPost<any>({
        url,
        body,
        options: {
          clsName: this.getClassName(),
          mthdName: 'downloadKubeconfig',
        },
      })
      return { response }
    } catch (error) {
      return { error: generateSunpikeErrorPayload(error).message }
    }
  }
  @trackApiMethodMetadata({
    url: '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/:namespace/clusteraddons',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Kubeconfig' },
  })
  getAllClusterAddons = async (): Promise<ClusterAddon[]> => {
    const url = '/sunpike/apis/sunpike.platform9.com/v1alpha2/clusteraddons'
    const result = await this.client.basicGet<ClusterAddons>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterAddons',
      },
    })
    return result?.items
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha1/hosts?labelSelector=cluster.x-k8s.io/cluster-name=:clusterName',
    type: 'GET',
    params: ['clusterName'],
  })
  getHostsForCapiCluster = async (clusterName: string) => {
    const clusterLabel = `cluster.x-k8s.io/cluster-name=${clusterName}`
    const capiHostLabel = `capi.pf9.io/managed=true`
    const baseUrl = `/sunpike/apis/sunpike.platform9.com/v1alpha1/hosts`
    const url = clusterName
      ? createUrlWithQueryString(baseUrl, { labelSelector: clusterLabel })
      : createUrlWithQueryString(baseUrl, { labelSelector: capiHostLabel })

    return await this.client.basicGet<GetCapiHostsResponse>({
      url,
      version: 'v1alpha1',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostsForCapiCluster',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/:identityName/eksclusterversions',
    type: 'GET',
    params: ['identityName'],
  })
  getEksVersions = async (identityName: string, roleArn?: string) => {
    const url = `/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/${identityName}/eksclusterversions${
      roleArn ? `?arn=${roleArn}` : ''
    }`

    const data = await this.client.basicGet<GetEksClusterVersionsResponse>({
      url,
      version: 'v1beta1',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEksVersions',
      },
    })
    return data.items
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/:identityName/region/:region',
    type: 'GET',
  })
  getAwsRegionInfo = async (identityName: string, region: string, roleArn?: string) => {
    const url = `${
      this.baseUrl
    }/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/${identityName}/region/${region}${
      roleArn ? `?arn=${roleArn}` : ''
    }`
    return await this.client.basicGet<AwsRegionInfo>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAwsRegionInfo',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/pmk.pf9.io/v1beta1/namespaces/:namespace/clusterupgradejobs',
    type: 'GET',
    params: ['namespace'],
  })
  getAllClusterUpgradeJobs = async (namespace = 'default') => {
    const url = `/sunpike/apis/pmk.pf9.io/v1beta1/namespaces/${namespace}/clusterupgradejobs`
    const data = await this.client.basicGet<GetClusterUpgradeJobsResponse>({
      url,
      version: 'v1alpha1',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllClusterUpgradeJobs',
      },
    })
    return data.items
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/pmk.pf9.io/v1beta1/namespaces/:namespace/clusterupgradejobs',
    type: 'POST',
    params: ['clusterName', 'namespace'],
  })
  createClusterUpgradeJob = async (body, namespace = 'default') => {
    const url = `/sunpike/apis/pmk.pf9.io/v1beta1/namespaces/${namespace}/clusterupgradejobs`
    return await this.client.basicPost<ClusterUpgradeJob>({
      url,
      version: 'v1alpha1',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterUpgradeJob',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/sunpike.platform9.com/v1alpha2/clusterversions',
    type: 'GET',
    error: { isClusterError: false, k8sResource: 'ClusterVersion' },
  })
  getClusterVersions = async () => {
    // const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/clusterversions?labelSelector=pmk.pf9.io/latest=true`
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/clusterversions`
    const data = await this.client.basicGet<any>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterVersions',
      },
    })
    return data.items
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/:identityName/eksamitypes',
    type: 'GET',
    params: ['identityName'],
    error: { isClusterError: true, k8sResource: 'EKSAMITypes' },
  })
  getEksAmiTypes = async (identityName) => {
    const url = `${this.baseUrl}/apis/opt.pf9.io/v1beta1/awsidentities/${identityName}/eksamitypes`
    const data = await this.client.basicGet<GetEksAmiTypesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEksAmiTypes',
      },
    })
    return data.items
  }

  /**
   * Prometheus proxy API.
   *
   */
  @trackApiMethodMetadata({
    url: `${sunpikeBaseUrlTpl}api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/query`,
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'CapiPrometheus' },
  })
  queryCCPrometheus = async (queryParams, cluster, queryType = 'query') => {
    const { clusterType, namespace, uuid: clusterId, name: clusterName } = cluster
    const url = constructApiUrl({
      url: `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/${queryType}`,
      clusterType,
      clusterId,
      clusterName,
      namespace,
      queryParams,
    })

    const data = await this.client.basicGet<PrometheusResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'queryCCPrometheus',
      },
    })
    return data?.result || []
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/:identityName/eksaddons?kubernetes-version=:version&arn=:arn',
    type: 'GET',
  })
  getEksAddons = async (identityName: string, version: string, roleArn?: string) => {
    const url = `${
      this.baseUrl
    }/sunpike/apis/opt.pf9.io/v1beta1/awsidentities/${identityName}/eksaddons?kubernetes-version=${version}${
      roleArn ? `&arn=${roleArn}` : ''
    }` // optinal query params
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEksAddons',
      },
    })
    return data.items
  }
}

export default Sunpike
