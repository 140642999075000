import { combineReducers } from 'redux'
import sessionReducers, { sessionStoreKey } from 'core/session/sessionReducers'
import cacheReducers, {
  cacheStoreKey,
  loadingStoreKey,
  dataStoreKey,
  updatingStoreKey,
  paramsStoreKey,
} from 'core/caching/cacheReducers'
import notificationReducers, { notificationStoreKey } from 'core/notifications/notificationReducers'
import preferencesReducers, { preferencesStoreKey } from 'core/session/preferencesReducers'
import clientReducers, { clientStoreKey } from 'core/client/clientReducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistReducer } from 'redux-persist'
import themeReducers, { customThemeKey } from 'core/session/themeReducers'

const persistCacheConfig = {
  key: cacheStoreKey,
  storage,
  blacklist: [loadingStoreKey, dataStoreKey, updatingStoreKey, paramsStoreKey],
}

const persistClientConfig = {
  key: clientStoreKey,
  storage,
  blacklist: ['frame'],
}

const persistSessionConfig = {
  key: sessionStoreKey,
  storage,
  blacklist: ['loadingRegion'],
}

const rootReducer = combineReducers({
  [sessionStoreKey]: persistReducer(persistSessionConfig, sessionReducers),
  [cacheStoreKey]: persistReducer(persistCacheConfig, cacheReducers),
  [notificationStoreKey]: notificationReducers,
  [preferencesStoreKey]: preferencesReducers,
  [customThemeKey]: themeReducers,
  [clientStoreKey]: persistReducer(persistClientConfig, clientReducers),
})

export default rootReducer
